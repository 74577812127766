import React, { ReactNode } from "react";
import {
	cn,
	DataObserveKey,
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	Icon,
	IconError,
	IconInfoOutline,
	IconSuccess,
	IconWarning,
	IconWarningOutline,
	VisualComponent,
} from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import { useDesignToken, useTheme } from "../../context/theme/theme";
import * as scss from "./message.scss";

export type MessageTypes = "neutral" | "positive" | "warning" | "critical" | "negative";

export type MessageProps = {
	/** The content of the message */
	children: ReactNode;
	/** Controls the color and icon of the message - defaults to "neutral" */
	type?: MessageTypes;
	/** Reduces the spacing and padding of the container */
	compact?: boolean;
} & DataObserveKey &
	VisualComponent;

export function Message(props: MessageProps): JSX.Element {
	const { children, type = "neutral", compact, className, style } = props;
	const label = useTypeToAriaLabel(type);

	return (
		<div
			data-component="message"
			data-observe-key={props["data-observe-key"]}
			role="group"
			className={cn(scss.message, scss[type], className, compact && scss.compact)}
			style={style}
			aria-label={label}
		>
			<div className={cn(scss.iconContainer, scss[type])}>{<TypeToIcon type={type} />}</div>
			<div className={cn(scss.contentContainer)}>{children}</div>
		</div>
	);
}

function useTypeToAriaLabel(type: MessageTypes) {
	const i18n = useLabTranslations();

	switch (type) {
		case "neutral":
			return i18n.messageNeutral;
		case "positive":
			return i18n.messagePositive;
		case "warning":
			return i18n.messageWarning;
		case "critical":
			return i18n.messageCritical;
		case "negative":
			return i18n.messageNegative;
	}
}

function TypeToIcon(props: { type: MessageTypes }) {
	const { ColorWhite, ColorYellowDarkest, ColorYellowDarker } = useDesignToken();
	const activeTheme = useTheme();
	switch (props.type) {
		case "neutral":
			return (
				<Icon fill={ColorWhite}>
					<IconInfoOutline />
				</Icon>
			);
		case "positive":
			return (
				<Icon fill={ColorWhite}>
					<IconSuccess />
				</Icon>
			);
		case "warning":
			return (
				<Icon fill={activeTheme === "legacy" ? ColorYellowDarker : ColorYellowDarkest}>
					<IconWarningOutline />
				</Icon>
			);
		case "critical":
			return (
				<Icon fill={ColorWhite}>
					<IconWarning />
				</Icon>
			);
		case "negative":
			return (
				<Icon fill={ColorWhite}>
					<IconError />
				</Icon>
			);
	}
}

type TitleProps = {
	/** The content (label)) of the title */
	children: ReactNode;
	/** The heading level of the title */
	headingLevel: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & VisualComponent;

function Title(props: TitleProps): JSX.Element {
	const { children, headingLevel, className } = props;

	let H = H1;
	switch (headingLevel) {
		case "h1":
			H = H1;
			break;
		case "h2":
			H = H2;
			break;
		case "h3":
			H = H3;
			break;
		case "h4":
			H = H4;
			break;
		case "h5":
			H = H5;
			break;
		case "h6":
			H = H6;
	}
	return (
		<H lookalike="h6" className={cn(scss.title, className)}>
			{children}
		</H>
	);
}

Message.Title = Title;
Message.iconSize = "large" as const;

export interface MessageFooterProps {
	children: React.ReactNode;
}

function MessageFooter({ children }: MessageFooterProps): JSX.Element {
	return <div className={scss.messageFooter}>{children}</div>;
}

Message.Footer = MessageFooter;
